import { useEffect, useState } from 'react'
import { axiosGet } from '.'
import { ASSETS_URL } from '../constants'
import { UIService } from '../services'
import * as themeConfig from '../theme'

interface LoginMethod {
  visible: boolean
  channels: string[]
}

export type OtpLoginMethods = {
  [x in 'email' | 'phone' | 'username']?: LoginMethod
}

export interface PortalConfig {
  authMode?: 'mfa' | 'otp' | 'password'
  mfaRememberDeviceEnabled?: boolean
  passwordRegexRule?: RegExp
  otpLoginMethods?: OtpLoginMethods
  defaultLanguage?: string
  languages?: string[]
  country?: string
}

interface FontConfig {
  fontUrl: string
  fontFamily: string
}

const useConfig = (): [PortalConfig, string] => {
  const [config, setConfig] = useState<PortalConfig>({})
  const [font, setFont] = useState<FontConfig>(null)

  useEffect(() => {
    if (!font) {
      axiosGet(`/fonts.json`, {}, {}, ASSETS_URL)
        .then((res) => {
          const url = res?.data?.href ? new URL(res?.data?.href) : null
          const fontFamily = url?.searchParams?.get('family')
          setFont({ fontFamily, fontUrl: res?.data?.href })
        })
        .catch(() => null)
    }

    const link = document.createElement('link')
    link.href = font?.fontUrl ?? themeConfig.fonts.fontUrl
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }, [config, font])

  useEffect(() => {
    let abortController = new AbortController()

    UIService.fetchConfig()
      .then((data) => {
        data && setConfig({ ...data })
      })
      .catch(() => null)

    return () => {
      abortController.abort()
    }
  }, [])

  return [config, font?.fontFamily]
}

export default useConfig
