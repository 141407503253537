import _ from 'lodash'

const VARIABLE_REGEX = /field\.([a-zA-Z0-9._]+)/g

export const getDependencies = (expression?: string) => {
  return _.compact(
    expression
      ?.match(VARIABLE_REGEX)
      ?.map((value: string) => value.split('.')[1])
  )
}
