import axios from 'axios'
import axiosRetry from 'axios-retry'
import { getToken, setToken } from '../helpers'
import { BASE_URL, LOCAL_STORAGE_KEYS, SERVICES } from '../constants'
import i18n from '../i18n'

const client = axios.create({
  baseURL: BASE_URL,
  timeout: 50000,
  headers: {
    'Content-Type': 'application/json',
  },
})

client.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${
    getToken(LOCAL_STORAGE_KEYS.AUTH).accessToken
  }`

  const { width, height } = window.screen
  const { clientWidth, clientHeight } = document.documentElement

  config.headers[
    'X-UA-Metadata'
  ] = `sr=${width}x${height}&wr=${clientWidth}x${clientHeight}&bl=${navigator.language}`

  config.headers['Accept-Language'] = i18n.language.replace('_', '-')

  return config
})

client.interceptors.response.use(
  (response) => {
    return response.data
  },
  async (error) => {
    if (error.response.status === 401) {
      try {
        const auth = getToken(LOCAL_STORAGE_KEYS.AUTH)

        const response = await axios.post(
          `${BASE_URL}${SERVICES.REFRESH_TOKEN}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${auth.refreshToken}`,
            },
          }
        )

        const { accessToken } = response.data

        setToken(LOCAL_STORAGE_KEYS.AUTH, { ...auth, accessToken })

        return client.request(error.config)
      } catch (e) {
        localStorage.clear()
        window.location.href = '/login'
      }
    }

    return Promise.reject(error)
  }
)

axiosRetry(client, { retries: 3 })

interface HttpClientInterface {
  get<T>(url: string, options?: any): Promise<T>
  post<T>(url: string, data: unknown, options?: any): Promise<T>
  put<T>(url: string, data: unknown): Promise<T>
  patch<T>(url: string, data: unknown): Promise<T>
  delete<T>(url: string): Promise<T>
}

export const HttpClient: HttpClientInterface = {
  async get<T>(url: string, options?: any) {
    const response = await client.get<T>(url, options)
    return response.data
  },

  async post<T>(url: string, data: unknown, options?: any) {
    const response = await client.post<T>(url, data, options)
    return response.data
  },

  async put<T>(url: string, data: unknown) {
    const response = await client.put<T>(url, data)
    return response.data
  },

  async patch<T>(url: string, data: unknown) {
    const response = await client.patch<T>(url, data)
    return response.data
  },

  async delete<T>(url: string) {
    const response = await client.delete<T>(url)
    return response.data
  },
}
