import React from 'react'
import { conditionalClasses } from '../../utils/theme'
import { LabelType } from '../../ui-library/types/label'
import {
  ResolvedLabelStyleReference,
  ResolvedReference,
} from '../../ui-library/types'
import { useFormContext } from 'react-hook-form'
import { useEngine } from '../../contexts/engine-context'
import { useLocation } from 'react-router-dom'
import { useCondition } from '../../../hooks/use-condition'

type Props = {
  className?: string
  label: LabelType
  inherit?: boolean
}

export const Label: React.FunctionComponent<Props> = ({
  label,
  className = '',
}) => {
  const { nunjucks } = useEngine()
  const { watch } = useFormContext()
  const location = useLocation()
  const values = watch()
  const cid =
    (new URLSearchParams(location.search).get('cid') as string) ?? 'default'

  const isConditionValid = useCondition(label?.condition, cid)

  if (!label || !label.text || !label.text.length) {
    return null
  }

  const { text, theme } = label

  const style = theme.style as ResolvedLabelStyleReference

  if (!isConditionValid) return null

  return (
    <div
      className={conditionalClasses(
        className,
        style.size,
        style.weight,
        'break-words'
      )}
      dangerouslySetInnerHTML={{
        __html: nunjucks.renderString(text, {
          field: values[cid],
        }),
      }}
      style={{ color: (theme.color as ResolvedReference).value }}
    />
  )
}
