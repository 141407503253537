import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react'
import { useMessages, MESSAGE_STATUS } from '../context/messages'
import { Navigate, useLocation } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { authService } from '../services'
import { TFunction } from 'i18next'
import { ROUTES } from '../constants'
import { isCustomerLoggedIn } from '../helpers'

type Props = {
  t: TFunction
} & PropsWithChildren

const MfaGuard: FunctionComponent<Props> = ({ children, t }) => {
  const { addToast } = useMessages()
  const { auth } = authService
  const location = useLocation()

  if (isCustomerLoggedIn(auth)) {
    return <Navigate to={ROUTES.ENGINE_DASHBOARD} />
  }

  if (!auth) {
    addToast(MESSAGE_STATUS.ERROR, t(['errors.notAuthenticated']))

    return (
      <Navigate
        to={ROUTES.LOGIN}
        state={{ from: `${location.pathname}?${location.search}` }}
      />
    )
  }

  return <>{children}</>
}

export default withTranslation()(MfaGuard)
