import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { TFunction, withTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { authService } from '../services'
import { ROUTES } from '../constants'
import { useMessages, MESSAGE_STATUS } from '../context/messages'
import InputField from '../components/InputField'
import Loader from '../components/Loader'
import {
  StyledFormButton,
  Heading,
  Caption,
  InputFieldContainer,
  StyledColumn,
  StyledGrid,
  StyledWrapper,
  StyledForm,
} from '../components/commonStyles'
import { getErrorMessage } from '../helpers'
import { AxiosError } from 'axios'
import useConfig from '../helpers/useConfig'

interface ChangePasswordProps {
  t: TFunction
}

interface Values {
  password: string
  password2: string
  oldPassword: string
}

const ChangePassword: React.FC<ChangePasswordProps> = (props) => {
  //const [message, setMessage] = React.useState('')

  const navigate = useNavigate()
  const { t } = props
  const { addToast } = useMessages()
  const [config] = useConfig()

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required(t(['forms.requiredField', ''])),
    password: Yup.string()
      .required(t(['forms.requiredField', '']))
      .matches(config.passwordRegexRule, t('changePassword.passwordRules', '')),
    password2: Yup.string().required(t(['forms.requiredField', ''])),
  })

  const handleSubmit = (
    values: Values,
    {
      setSubmitting,
    }: { setSubmitting: React.Dispatch<React.SetStateAction<boolean>> }
  ) => {
    if (values.password === values.password2) {
      authService
        .changePassword(values.oldPassword, values.password)
        .then(() => {
          setSubmitting(false)
          addToast(MESSAGE_STATUS.SUCCESS, t(['changePassword.200', '']))
          navigate(ROUTES.LOGIN)
        })
        .catch((e: AxiosError) => {
          setSubmitting(false)
          const errorMessage = getErrorMessage(e, 'changePassword')
          addToast(MESSAGE_STATUS.ERROR, errorMessage)
        })
    } else {
      setSubmitting(false)
      addToast('error', t('changePassword.passwordsNotMatch', ''))
    }
  }

  return (
    <StyledWrapper align="center" margin="125px auto">
      <StyledGrid halign="center">
        <StyledColumn
          size={{ md: 4 / 8, lg: 3 / 12 }}
          halign="center"
          direction="column"
        >
          <Heading
            dangerouslySetInnerHTML={{
              __html: t('changePassword.heading', ''),
            }}
          />
          <Caption
            dangerouslySetInnerHTML={{
              __html: t('changePassword.caption', ''),
            }}
          />
          <Formik
            initialValues={{
              oldPassword: '',
              password: '',
              password2: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <StyledForm onSubmit={formik.handleSubmit}>
                <InputFieldContainer>
                  <InputField
                    id="oldPassword"
                    label={t('changePassword.oldPasswordLabel', '')}
                    type="password"
                    formik={formik}
                  />
                  <InputField
                    id="password"
                    label={t('changePassword.newPasswordLabel', '')}
                    type="password"
                    formik={formik}
                  />
                  <InputField
                    id="password2"
                    label={t('changePassword.retypePasswordLabel', '')}
                    type="password"
                    formik={formik}
                  />
                </InputFieldContainer>
                <div>
                  <StyledFormButton
                    type="submit"
                    id="submit"
                    disabled={!(formik.isValid && formik.dirty)}
                  >
                    {formik.isSubmitting ? (
                      <Loader />
                    ) : (
                      <span>{t('changePassword.confirmButtonText', '')}</span>
                    )}
                  </StyledFormButton>
                </div>
              </StyledForm>
            )}
          </Formik>
        </StyledColumn>
      </StyledGrid>
    </StyledWrapper>
  )
}

export default withTranslation()(ChangePassword)
