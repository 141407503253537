import { FunctionComponent } from 'react'

import { CardFactory } from '../card/card-factory'
import { SideContentType } from '../ui-library/types/page'
import { useLocation } from 'react-router-dom'

type Props = {
  content: SideContentType
}

export const SideContent: FunctionComponent<Props> = ({ content }) => {
  const { key } = useLocation()
  const { cards } = content

  return (
    <div className="gap-4 flex flex-col">
      {cards.map((card, index) => (
        <CardFactory card={card} key={`${key}-${index}`} />
      ))}
    </div>
  )
}
