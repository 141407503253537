import { FunctionComponent, useEffect, useMemo } from 'react'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../components/ui/popover'
import { FormControl } from '../../components/ui/form'
import { Button } from '../../components/ui/button'
import { Check, ChevronDown } from 'lucide-react'
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from '../../components/ui/command'
import { useEngine } from '../../contexts/engine-context'
import { FieldSelect, SelectValueType } from '../types'
import { cn } from '../../utils/shadcn'
import { Label } from '../../components/ui/label'
import { Checkbox } from '../../components/ui/checkbox'

interface Props {
  className?: string
  radioClassIndicator?: string
  currentValue: string[]
  item: FieldSelect
  values: SelectValueType[]
  onChange: (value: string[]) => void
  context: Record<string, any>
  dependecies: string[]
}

export const MultiRadioSelect: FunctionComponent<Props> = ({
  item,
  currentValue,
  className,
  radioClassIndicator,
  values,
  onChange,
  context,
  dependecies,
}) => {
  const { nunjucks } = useEngine()
  const dependentValues = useMemo(
    () => dependecies.map((key) => context?.[key]),
    [dependecies, context]
  )

  useEffect(() => {
    onChange(
      currentValue?.filter((key) => values.map(({ key }) => key).includes(key))
    )
  }, dependentValues)

  return (
    <div className="space-y-2">
      {(item.values || [])
        .filter(({ condition }) =>
          condition
            ? nunjucks.renderString(condition, {
                field: Object.fromEntries(
                  Object.entries(context || {}).map(([key, value]) => [
                    key,
                    !isNaN(Number(value)) ? Number(value) : value,
                  ])
                ),
              }) === 'true'
            : true
        )
        .map(({ key, value }) => (
          <Label
            htmlFor={key}
            key={key}
            className={cn(
              className,
              'flex items-center space-x-2 group cursor-pointer min-h-10',
              currentValue?.includes(key) && 'bg-white'
            )}
          >
            <Checkbox
              id={key}
              checked={currentValue?.includes(key)}
              onCheckedChange={() => {
                onChange(
                  currentValue?.some((value) => value === key)
                    ? currentValue?.filter((value) => value !== key)
                    : item.maxValues > (currentValue || []).length
                    ? [...(currentValue || []), key]
                    : [...(currentValue || []).slice(1), key]
                )
              }}
              className={radioClassIndicator}
            />
            <span className="text-sm">{value.text}</span>
          </Label>
        ))}
    </div>
  )
}
