import { css } from '@emotion/css'
import { FunctionComponent } from 'react'
import { Button, Label, ResolvedReference } from '../ui-library'
import { PageHeaderType } from '../ui-library/types/page'
import { cn } from '../utils/shadcn'

type Props = {
  header: PageHeaderType
}

export const Header: FunctionComponent<Props> = ({ header }) => {
  const { title, description, button, theme } = header

  const className = css`
    background-color: ${(theme.backgroundColor as ResolvedReference).value};
  `

  return (
    <div className={cn(className, 'flex items-center justify-between')}>
      <div>
        <Label label={title} />
        <Label label={description} />
      </div>
      <div>
        <Button item={button} />
      </div>
    </div>
  )
}
