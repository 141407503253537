import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

const LoadingWrapper = styled.div`
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 ${(props) => props.theme.gutters.container.sm}px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    padding: 0 ${(props) => props.theme.gutters.container.md}px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}px) {
    padding: 0 ${(props) => props.theme.gutters.container.lg}px;
  }

  .stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 2rem 0;
    margin: 0 -5%;
    overflow: hidden;
  }

  .dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--elements-loader-pageColor);
    color: var(--elements-loader-pageColor);
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--elements-loader-pageColor);
    color: var(--elements-loader-pageColor);
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--elements-loader-pageColor);
    color: var(--elements-loader-pageColor);
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: var(--elements-loader-pageColor);
    }
    50%,
    100% {
      background-color: #ebe6ff;
    }
  }
`
interface LoadingOptionsProps {
  t: TFunction
  loading: boolean
  text?: string
  loadingText?: string
}

const LoadingOptions: React.FC<LoadingOptionsProps> = (props) => {
  const { t, loading, text = '', loadingText = '' } = props
  return (
    <LoadingWrapper>
      {loading ? (
        <div>
          <div className="stage">
            <div className="dot-flashing"></div>
          </div>
          <span>
            {loadingText ? loadingText : (t('dashboard.loading', '') as string)}
          </span>
        </div>
      ) : (
        <span>
          {text ? text : (t('errors.noOptionsAvailable', '') as string)}
        </span>
      )}
    </LoadingWrapper>
  )
}

export default withTranslation()(LoadingOptions)
