import React from 'react'

import { ResolvedReference, RowSectionType } from '../../types'
import { Divider } from '../divider'
import { Label } from '../label'

type Props = {
  row: RowSectionType
  selectTitle?: () => void
  selectDescription?: () => void
  selectHorizontalLine?: () => void
  selectVerticalLine?: () => void
}

export const RowSection: React.FunctionComponent<Props> = ({
  row,
  selectTitle,
  selectDescription,
  selectHorizontalLine,
  selectVerticalLine,
}) => {
  const { title, description } = row

  const backgroundColor = (row.theme?.backgroundColor as ResolvedReference)
    .value

  return (
    <div className="flex items-stretch" style={{ backgroundColor }}>
      <div className="" onClick={selectVerticalLine}>
        {row.verticalLine && !row.verticalLine.hidden && (
          <Divider
            className="h-full my-0 mr-3"
            color={(row.verticalLine.theme.color as ResolvedReference).value}
            style={row.verticalLine.theme.style}
            width={row.verticalLine.theme.width}
            direction="vertical"
            condition={row.verticalLine.condition}
          />
        )}
      </div>
      <div className="flex-1 flex flex-col">
        <div className="flex flex-1 space-x-4">
          <div className="flex" onClick={selectTitle}>
            <Label label={title} />
          </div>
          <div className="flex-1" onClick={selectHorizontalLine}>
            {row.horizontalLine && !row.horizontalLine.hidden && (
              <Divider
                color={
                  (row.horizontalLine.theme.color as ResolvedReference).value
                }
                style={row.horizontalLine.theme.style}
                width={row.horizontalLine.theme.width}
                condition={row.horizontalLine.condition}
              />
            )}
          </div>
        </div>
        <div onClick={selectDescription}>
          <Label label={description} />
        </div>
      </div>
    </div>
  )
}
