import React from 'react'

import { RowImageType as Row } from '../../types/row'
import { ResolvedReference } from '../../types'
import { useCondition } from '../../../../hooks/use-condition'
import { useLocation } from 'react-router-dom'

type Props = {
  row: Row
  selectImage?: () => void
}

export const RowImage: React.FunctionComponent<Props> = ({
  row,
  selectImage,
}) => {
  const location = useLocation()

  const cid =
    (new URLSearchParams(location.search).get('cid') as string) ?? 'default'

  const { image } = row

  const backgroundColor = (row.theme.backgroundColor as ResolvedReference).value

  const isConditionValid = useCondition(image?.condition, cid)

  if (!isConditionValid) return null

  return (
    <div className="flex-1 flex flex-col" style={{ backgroundColor }}>
      <div onClick={selectImage}>
        {image && (
          <img
            className="w-full"
            src={image.source}
            alt={image.alternateText.text}
          />
        )}
      </div>
    </div>
  )
}
