import React from "react";
import { UseFormReturn, FormProvider as Form } from "react-hook-form";

type Props = {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
  className?: string;
};

export const FormProvider: React.FunctionComponent<Props> = ({
  children,
  onSubmit,
  methods,
  className,
}) => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit?.();
  };

  return (
    <Form {...methods}>
      <form className={className} onSubmit={handleSubmit}>
        {children}
      </form>
    </Form>
  );
};
