import { Button as BaseButton } from '../../components/ui/button'
import React, { useState } from 'react'

import { css } from '@emotion/css'
import { ButtonIconPosition, ButtonType } from '../types/button'
import { cn } from '../../utils/shadcn'
import { ResolvedButtonStyleReference, ResolvedReference } from '../types'
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { HttpClient, Mode, useEngine } from '../../contexts/engine-context'
import { icons } from 'lucide-react'
import { useCondition } from '../../../hooks/use-condition'
import { GeneralPageType, PageType } from '../types/page'
import { trackPageView } from '../../utilities/analytics'
import { setRefreshOptionsFlag } from '../../utilities/page'

interface ButtonProps {
  item?: ButtonType
}

const handleButtonNavigation = async (
  newPage: GeneralPageType,
  newPageUrl: string,
  mode: Mode,
  httpClient: HttpClient,
  navigate: NavigateFunction
) => {
  if (newPage.type === PageType.EXTERNAL) {
    if (mode === Mode.LIVE) {
      await trackPageView(newPage, httpClient)
    }

    if (newPage.refreshOptions) {
      setRefreshOptionsFlag()
    }

    window.open(newPage.url, newPage.target)
    // TODO: Add service page logic
  } else if (newPage.type === PageType.INTERNAL) {
    navigate(newPageUrl)
  }
}

export const Button: React.FunctionComponent<ButtonProps> = ({ item }) => {
  const { trigger, watch } = useFormContext()
  const {
    pageFields,
    mode,
    enginePath: prefix,
    nunjucks,
    actionErrorCallback,
    httpClient,
    config,
  } = useEngine()
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = `${location.pathname.replace(prefix, '')}${
    location.search
  }`
  const [isLoading, setIsLoading] = useState(false)

  const values = watch()

  const cid =
    (new URLSearchParams(location.search).get('cid') as string) ?? 'default'

  const isConditionValid = useCondition(item?.condition, cid)

  if (!item || !isConditionValid) {
    return null
  }

  const { theme, text, icon } = item
  const style = theme.style as ResolvedButtonStyleReference

  const className = theme
    ? cn(
        'w-full overflow-hidden',
        style.borderRadius,
        style.borderStyle,
        style?.borderWidth,
        css`
          padding-top: ${style?.paddingTop}px !important;
          padding-right: ${style?.paddingRight}px !important;
          padding-bottom: ${style?.paddingBottom}px !important;
          padding-left: ${style?.paddingLeft}px !important;

          background-color: ${style.style === 'textButton'
            ? 'transparent'
            : (style.state.default.backgroundColor as ResolvedReference).value};
          color: ${(style.state.default.textColor as ResolvedReference).value};
          border-color: ${style.style === 'textButton'
            ? 'transparent'
            : (style.state.default.borderColor as ResolvedReference).value};
          &:hover {
            background-color: ${style.style === 'textButton'
              ? 'transparent'
              : (style.state.hover.backgroundColor as ResolvedReference).value};
            color: ${(style.state.hover.textColor as ResolvedReference).value};
            border-color: ${style.style === 'textButton'
              ? 'transparent'
              : (style.state.hover.borderColor as ResolvedReference).value};
          }
        `
      )
    : ''

  const handleClick = () => {
    const currentPageFields = pageFields[currentPath].map(
      ({ field, cid }) => `${cid}.${field.id}`
    )

    const validUrl = item.urls.find((item) =>
      item.condition
        ? nunjucks.renderString(item.condition, {
            field: Object.fromEntries(
              Object.entries(values[cid || 'default']).map(([key, value]) => [
                key,
                !isNaN(Number(value)) ? Number(value) : value,
              ])
            ),
          }) === 'true'
        : true
    )

    if (!validUrl) {
      console.error('Invalid configuartion')
      return
    }

    const page = config[validUrl.path]

    if (!page) {
      console.error('Invalid page configuration')
      return
    }

    trigger(currentPageFields).then(async (isValid) => {
      const newPageUrl = `${prefix}${validUrl.path}`
      if (isValid) {
        if (validUrl?.actions?.length && mode === Mode.LIVE) {
          const actions = validUrl.actions.map((action) =>
            JSON.parse(
              nunjucks.renderString(JSON.stringify(action), {
                field: values[cid],
              })
            )
          )

          setIsLoading(true)

          httpClient
            .post('/me/actions', { actions })
            .then(async () => {
              await handleButtonNavigation(
                page,
                newPageUrl,
                mode,
                httpClient,
                navigate
              )
            })
            .catch((error) => {
              actionErrorCallback(error)
            })
            .finally(() => setIsLoading(false))
        } else {
          await handleButtonNavigation(
            page,
            newPageUrl,
            mode,
            httpClient,
            navigate
          )
        }
      } else {
        document
          .querySelector('.internal-form-error-message-flag')
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    })
  }

  return (
    <BaseButton
      className={className}
      loading={isLoading}
      onClick={handleClick}
      leadIcon={
        icon?.position === ButtonIconPosition.START && icon?.name
          ? icons[icon?.name]
          : undefined
      }
      tailIcon={
        icon?.position === ButtonIconPosition.END && icon?.name
          ? icons[icon?.name]
          : undefined
      }
    >
      {text}
    </BaseButton>
  )
}
