import { FunctionComponent, PropsWithChildren } from 'react'
import { isCustomerLoggedIn } from '../helpers'
import { authService } from '../services'
import { ROUTES } from '../constants'
import { Navigate } from 'react-router-dom'

export const GuestGuard: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { auth } = authService

  if (isCustomerLoggedIn(auth)) {
    return <Navigate to={ROUTES.ENGINE_DASHBOARD} />
  }

  return <>{children}</>
}
