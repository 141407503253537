import { Checkbox } from '../../../components/ui/checkbox'
import React, { useEffect } from 'react'

import { cn } from '../../../../engine/utils/shadcn'
import { RowCheckboxType as Row } from '../../../ui-library/types'
import { Label } from '../label'
import { Label as FieldLabel } from '../../../components/ui/label'

import {
  FieldCheckboxStyle,
  ResolvedFieldStyleReference,
  ResolvedReference,
} from '../../../ui-library/types'
import * as SwitchPrimitives from '@radix-ui/react-switch'
import { css } from '@emotion/css'
import { FormField } from '../../../components/ui/form'
import { useLocation } from 'react-router-dom'
import ErrorMessage from '../error-message'
import { useFormContext } from 'react-hook-form'
import { useCondition } from '../../../../hooks/use-condition'

type Props = {
  row: Row
}

export const RowCheckbox: React.FunctionComponent<Props> = ({ row }) => {
  const { justify, field: item } = row
  const location = useLocation()
  const { trigger, setValue, watch } = useFormContext()

  const cid =
    (new URLSearchParams(location.search).get('cid') as string) ?? 'default'

  const isConditionValid = useCondition(row.field.condition, cid)

  // Reset field value if condition is not valid
  useEffect(() => {
    if (!isConditionValid) {
      setValue(dataKey, item.checked ? item.checkedValue : item.uncheckedValue)
    }
  }, [isConditionValid])

  const { id } = item

  const dataKey = `${cid}.${id}`

  const backgroundColor = (row.theme.backgroundColor as ResolvedReference).value

  const toggleClassname = css`
    background-color: ${(item.theme as ResolvedFieldStyleReference).style
      .borderFocusColor.value};
  `

  const handleBlur = async (
    key: string,
    value: string | number | string[] | number[] | boolean
  ) => {
    const isValid = await trigger(key)
    if (isValid) {
      //   EngineService.saveField(key, value)
    }
  }

  useEffect(() => {
    if (item.reset || [undefined, null].includes(watch(dataKey))) {
      setValue(dataKey, item.checked ? item.checkedValue : item.uncheckedValue)
    }
  }, [item])

  const checkboxClassname = css`
    border-color: ${
      (item.theme as ResolvedFieldStyleReference).style.borderColor.value
    };
    &[data-state='checked'] {
      border-color: ${
        (item.theme as ResolvedFieldStyleReference).style.borderFocusColor.value
      };
      background-color: ${
        (item.theme as ResolvedFieldStyleReference).style.borderFocusColor.value
      };
  `

  return (
    <div>
      <div
        className="flex flex-row justify-end space-x-2"
        style={{
          justifyContent: justify || 'start',
          backgroundColor,
        }}
      >
        <div className="items-top space-y-2">
          <FormField
            name={dataKey}
            render={({ field }) => (
              <div className={'flex items-center space-x-2'}>
                {isConditionValid &&
                  !row.field.hidden &&
                  row.field.style === 'checkbox' && (
                    <Checkbox
                      id={row.field.id}
                      className={cn(checkboxClassname, 'bg-white h-5 w-5')}
                      color={
                        (item.theme as ResolvedFieldStyleReference).style
                          .borderFocusColor.value
                      }
                      checked={field.value === item.checkedValue}
                      onBlur={() => handleBlur(dataKey, field.value)}
                      onCheckedChange={(value) => {
                        field.onChange(
                          value ? item.checkedValue : item.uncheckedValue
                        )
                        handleBlur(dataKey, field.value)
                      }}
                    />
                  )}

                {row.field.style === 'checkbox' && (
                  <>
                    <label className="cursor-pointer" htmlFor={row.field.id}>
                      <Label label={row.title} />
                    </label>
                    {/* TODO: Remove on release */}
                    <span className="text-xs text-muted-foreground">
                      <span className="font-bold">{item.id}</span>
                    </span>
                  </>
                )}

                {item.style === FieldCheckboxStyle.Toggle && (
                  <>
                    {isConditionValid && (
                      <SwitchPrimitives.Root
                        className={cn(
                          'shadow-inner peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=unchecked]:bg-input',
                          toggleClassname
                        )}
                        checked={field.value === item.checkedValue}
                        onCheckedChange={(value) =>
                          field.onChange(
                            value ? item.checkedValue : item.uncheckedValue
                          )
                        }
                      >
                        <SwitchPrimitives.Thumb
                          className={cn(
                            'pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0'
                          )}
                        />
                      </SwitchPrimitives.Root>
                    )}
                    <FieldLabel htmlFor={row.field.id}>
                      <Label label={row.title} />
                    </FieldLabel>
                    {/* TODO: Remove on release */}
                    <span className="text-xs text-muted-foreground">
                      <span className="font-bold">{item.id}</span>
                    </span>
                  </>
                )}
              </div>
            )}
          />
          <div className="grid gap-1.5 leading-none">
            <p className="text-sm text-muted-foreground">
              <Label label={row.description} />
            </p>
          </div>
        </div>
      </div>
      <ErrorMessage name={dataKey} />
    </div>
  )
}
