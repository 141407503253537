import {
  axiosAuthPost,
  axiosRefreshAuthPost,
  axiosPost,
  axiosAuthGet,
  setToken,
  getToken,
  removeToken,
  getAuthHeader,
  axiosTempPost,
  axiosAuthDelete,
  axiosAuthHead,
  getDeviceIdHeader,
  isLoggedIn,
} from '../helpers'
import {
  ASSETS_URL,
  LOCAL_STORAGE_KEYS,
  SERVICES,
  STATUS_CODES,
} from '../constants'
import axios from 'axios'

const changePassword = (oldPassword: string, newPassword: string) => {
  return axiosAuthPost(SERVICES.CHANGE_PSSW, { oldPassword, newPassword })
}

const forgotPassword = (email: string) => {
  return axiosPost(SERVICES.FORGOT_PSSW, { email })
}

const login = async (
  username: string,
  password: string,
  deviceId: string = ''
) => {
  const res = await axiosPost(
    SERVICES.LOGIN,
    { username, password },
    getDeviceIdHeader(deviceId)
  )

  const { mfaRequired, otpChannels, ...tokens } = res.data
  setToken(LOCAL_STORAGE_KEYS.AUTH, tokens)

  if (isLoggedIn(tokens)) {
    fetchUser()
  }

  return res
}

const otpLogin = async (id: string, type?: string) => {
  const res = await axiosPost(SERVICES.PASSWORDLESS_LOGIN, { id, type })

  const { otpChannels, ...tokens } = res.data
  setToken(LOCAL_STORAGE_KEYS.AUTH, tokens)

  return res
}

const sendOtp = (channel: 'email' | 'phone') => {
  return axiosAuthPost(SERVICES.SEND_OTP, { channel })
}

const verifyOtp = async (otp: string, rememberDevice: boolean) => {
  const res = await axiosAuthPost(SERVICES.OTP, { otp, rememberDevice })
  const { deviceId, ...tokens } = res.data

  setToken(LOCAL_STORAGE_KEYS.AUTH, tokens)
  if (deviceId) setToken(LOCAL_STORAGE_KEYS.DEVICE_ID, deviceId)

  fetchUser()
}

const logout = () => {
  axiosAuthPost(SERVICES.LOGOUT)
  removeToken(LOCAL_STORAGE_KEYS.AUTH)
  removeToken(LOCAL_STORAGE_KEYS.USER)
  removeToken(LOCAL_STORAGE_KEYS.USER_OPTIONS)
  removeToken(LOCAL_STORAGE_KEYS.HISTORY)
  removeToken(LOCAL_STORAGE_KEYS.CACHE)
  window.dispatchEvent(new Event('updateUser'))

  // removeToken(LOCAL_STORAGE_KEYS.UI_CONFIG)
  return Promise.resolve('User logged out')
}

const newPassword = async (newPassword: string, token: string = '') => {
  await axiosTempPost(SERVICES.NEW_PSSW, { newPassword }, getAuthHeader(token))
  return removeToken(LOCAL_STORAGE_KEYS.TEMP_AUTH)
}

const refreshToken = async () => {
  const res = await axiosRefreshAuthPost(SERVICES.REFRESH_TOKEN)
  const { accessToken } = res.data
  const auth = getToken(LOCAL_STORAGE_KEYS.AUTH)
  setToken(LOCAL_STORAGE_KEYS.AUTH, { ...auth, accessToken })
}

const register = (username: string) => {
  return axiosPost(SERVICES.REGISTER, { username })
}

const resendCode = (accessToken: string) => {
  return axiosPost(SERVICES.RESEND_CODE, { accessToken })
}

const verifyCode = async (accessToken: string, verificationCode: string) => {
  const res = await axiosPost(SERVICES.REGISTER_CODE, {
    accessToken,
    verificationCode,
  })
  return setToken(LOCAL_STORAGE_KEYS.TEMP_AUTH, res.data)
}

const refreshCache = (keysToPreserve = []) => {
  const items = keysToPreserve.map((key) => ({
    key,
    value: getToken(key),
  }))
  localStorage.clear()
  // TODO revisit implementation
  window.dispatchEvent(new Event('updateUser'))
  items.forEach(({ key, value }) => setToken(key, value))
}

export const authService = {
  changePassword,
  forgotPassword,
  login,
  otpLogin,
  sendOtp,
  verifyOtp,
  logout,
  newPassword,
  refreshToken,
  register,
  resendCode,
  verifyCode,
  refreshCache,
  get auth() {
    return getToken(LOCAL_STORAGE_KEYS.AUTH)
  },
  get temp() {
    return getToken(LOCAL_STORAGE_KEYS.TEMP_AUTH)
  },
  get deviceId() {
    return getToken(LOCAL_STORAGE_KEYS.DEVICE_ID)
  },
}

const fetchConfig = async () => {
  const res = await axios.get(`${ASSETS_URL}/portal-config.json`)
  if (res.status && res.status === STATUS_CODES.SUCCESS) {
    setToken(LOCAL_STORAGE_KEYS.CONFIG, res.data)
    return res.data
  }
  return res
}

const fetchUser = async () => {
  const user = getToken(LOCAL_STORAGE_KEYS.USER)

  if (user) {
    return Promise.resolve(user)
  }

  try {
    const res = await axiosAuthGet(SERVICES.USER)
    if (res.status && res.status === STATUS_CODES.SUCCESS) {
      setToken(LOCAL_STORAGE_KEYS.USER, res.data)
      window.dispatchEvent(new Event('updateUser'))
      return res.data
    }
    return res
  } catch (e) {
    return await Promise.reject(e)
  }
}

const fetchUserOptions = async (params?: any) => {
  const userOptions = getToken(LOCAL_STORAGE_KEYS.USER_OPTIONS)

  if (userOptions) {
    return Promise.resolve(userOptions)
  }

  return axiosAuthGet(
    SERVICES.USER_OPTIONS,
    { 'Cache-Control': 'no-cache' },
    params
  )
    .then((res) => {
      if (res.status && res.status === STATUS_CODES.SUCCESS) {
        setToken(LOCAL_STORAGE_KEYS.USER_OPTIONS, res.data)
        return res.data
      }
      return res
    })
    .catch((e) => {
      return Promise.reject(e)
    })
}

const postUserOptions = async (userOptions) => {
  await axiosAuthPost(SERVICES.POST_USER_OPTIONS, userOptions)
  return removeToken(LOCAL_STORAGE_KEYS.USER_OPTIONS)
}

const getRedirectLink = (id: string) => {
  return axiosPost(`${SERVICES.REDIRECT_LINK}/${id}`).then((res) => {
    if (res.status && res.status === STATUS_CODES.SUCCESS) {
      //setToken(LOCAL_STORAGE_KEYS.HISTORY, res.data)
      return res.data
    }
    return res
  })
}

const postCustomerCache = (
  caseId: string,
  flowId: string,
  flowInstanceId: string,
  optionCode: string,
  payload
) => {
  const url = `${SERVICES.CACHE}/${caseId}/${flowId}/${flowInstanceId}/${optionCode}`
  return axiosAuthPost(url, payload)
}

const deleteCustomerCache = (
  caseId: string,
  flowId: string,
  flowInstanceId: string,
  optionCode: string,
  fieldKey: string
) => {
  const url = `${SERVICES.CACHE}/${caseId}/${flowId}/${flowInstanceId}/${optionCode}/${fieldKey}`
  return axiosAuthDelete(url)
}

const deleteCustomerCacheForCase = (
  caseId: string,
  flowId: string,
  flowInstanceId: string
) => {
  const url = `${SERVICES.CACHE}/${caseId}/${flowId}/${flowInstanceId}`
  return axiosAuthDelete(url)
}

const deleteAllCachedData = () => {
  const url = `${SERVICES.CACHE}`
  return axiosAuthDelete(url)
}

const getCustomerCache = (
  caseId: string,
  flowId: string,
  flowInstanceId: string
) => {
  const url = `${SERVICES.CACHE}/${caseId}/${flowId}/${flowInstanceId}`
  return axiosAuthGet(url)
}

const headCustomerCache = (
  caseId: string,
  flowId: string,
  flowInstanceId: string
) => {
  const url = `${SERVICES.CACHE}/${caseId}/${flowId}/${flowInstanceId}`
  return axiosAuthHead(url)
}

export interface AnalyticsTag {
  name: string
  successfulEnd: boolean
  flowId: string
  flowName: string
}

const postPageView = (analyticsTag: AnalyticsTag, journeyId: number) => {
  const url = `${SERVICES.PAGE_VIEWS}`
  return axiosAuthPost(url, {
    journeyId: journeyId.toString(),
    analyticsTag: analyticsTag,
  })
}

const postIntegrationServices = (serviceId: string, payload = {}) => {
  const url = `${SERVICES.INTEGRATION_SERVICES}/${serviceId}`
  return axiosAuthPost(url, payload)
}

export const UIService = {
  fetchConfig,
  fetchUser,
  fetchUserOptions,
  postUserOptions,
  postIntegrationServices,
  getRedirectLink,
  postCustomerCache,
  deleteCustomerCache,
  getCustomerCache,
  headCustomerCache,
  deleteAllCachedData,
  deleteCustomerCacheForCase,
  postPageView,
  logout,
  get user() {
    return getToken(LOCAL_STORAGE_KEYS.USER)
  },
}
