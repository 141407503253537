import { FunctionComponent, useEffect, useMemo } from 'react'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../components/ui/popover'
import { FormControl } from '../../components/ui/form'
import { Button } from '../../components/ui/button'
import { Check, ChevronDown } from 'lucide-react'
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from '../../components/ui/command'
import { useEngine } from '../../contexts/engine-context'
import { FieldSelect, SelectValueType } from '../types'
import { cn } from '../../utils/shadcn'

interface Props {
  className?: string
  currentValue: string[]
  item: FieldSelect
  values: SelectValueType[]
  onChange: (value: string[]) => void
  context: Record<string, any>
  dependecies: string[]
}

export const Multiselect: FunctionComponent<Props> = ({
  item,
  currentValue,
  className,
  values,
  onChange,
  context,
  dependecies,
}) => {
  const { nunjucks } = useEngine()
  const dependentValues = useMemo(
    () => dependecies.map((key) => context?.[key]),
    [dependecies, context]
  )

  useEffect(() => {
    onChange(
      currentValue?.filter((key) => values.map(({ key }) => key).includes(key))
    )
  }, dependentValues)

  return (
    <Popover>
      <PopoverTrigger asChild>
        <FormControl>
          <Button
            variant="outline"
            role="combobox"
            className={cn(
              'flex min-h-10 h-auto w-full justify-between rounded-md border border-input bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 whitespace-normal',
              className
            )}
          >
            {(currentValue || [])
              .map(
                (value) =>
                  item.values?.find(({ key }) => key === value).value?.text
              )
              .join(', ') || 'Select'}

            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent
        className="p-0 w-[--radix-popover-trigger-width]"
        align="start"
      >
        <Command className="w-full">
          <CommandList>
            <CommandGroup>
              {values
                .filter(({ condition }) =>
                  condition
                    ? nunjucks.renderString(condition, {
                        field: Object.fromEntries(
                          Object.entries(context || {}).map(([key, value]) => [
                            key,
                            !isNaN(Number(value)) ? Number(value) : value,
                          ])
                        ),
                      }) === 'true'
                    : true
                )
                .map(({ value, key }) => (
                  <CommandItem
                    value={value.text}
                    key={key}
                    className={cn(
                      'cursor-pointer',
                      currentValue?.some((value: string) => value === key)
                        ? 'text-primary'
                        : ''
                    )}
                    onSelect={() => {
                      onChange(
                        currentValue?.some((value: string) => value === key)
                          ? currentValue?.filter(
                              (value: string) => value !== key
                            )
                          : item.maxValues > (currentValue || []).length
                          ? [...(currentValue || []), key]
                          : [...(currentValue || []).slice(1), key]
                      )
                    }}
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4 text-primary',
                        currentValue?.some((value: string) => value === key)
                          ? 'opacity-100'
                          : 'opacity-0'
                      )}
                    />
                    {value.text}
                  </CommandItem>
                ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
