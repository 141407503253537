import * as yup from 'yup'

import {
  FieldCheckbox,
  FieldDate,
  FieldNumber,
  FieldSelect,
  FieldText,
  FieldType,
  GeneralFieldType,
} from '../ui-library'
import nunjucks from '../utils/nunjucks'
import { getDependencies } from '../utilities/dependencies'

const evaluateExpression = (
  expression: string,
  fields: any[],
  dependencies: string[]
) => {
  const assembledFields = fields.reduce((acc, field, index) => {
    acc[dependencies[index]] = field
    return acc
  }, {})

  return eval(nunjucks.renderString(expression, { field: assembledFields }))
}

export const fieldValidationTextFactory = (field: FieldText) => {
  let rules = yup.string()

  if (field.regex) {
    rules = rules.matches(new RegExp(field.regex), {
      message: field.regexError.text,
    })
  }

  if (field.required) {
    rules = rules.required()
  }

  if (field.minLength) {
    rules = rules.min(field.minLength)
  }

  if (field.maxLength) {
    rules = rules.max(field.maxLength)
  }

  return rules
}

export const fieldValidationNumberFactory = (field: FieldNumber) => {
  let rules = yup.number().nullable()

  if (field.required) {
    rules = rules.required()
  }

  if (field.round) {
    rules = rules.integer()
  }

  if (![undefined, null].includes(field.maxValue)) {
    const maxValue = parseFloat(field.maxValue as string)
    if (!isNaN(maxValue)) {
      rules = rules.max(maxValue)
    } else {
      const dependencies = getDependencies(field.maxValue as string)

      if (dependencies.length > 0) {
        rules = rules.when(dependencies, (fields, schema) => {
          return schema.max(
            evaluateExpression(field.maxValue as string, fields, dependencies)
          )
        })
      }
    }
  }

  if (![undefined, null].includes(field.minValue)) {
    const minValue = parseFloat(field.minValue as string)
    if (!isNaN(minValue)) {
      rules = rules.min(minValue)
    } else {
      const dependencies = getDependencies(field.minValue as string)

      if (dependencies.length > 0) {
        rules = rules.when(dependencies, (fields, schema) => {
          return schema.min(
            evaluateExpression(field.minValue as string, fields, dependencies)
          )
        })
      }
    }
  }

  return rules
}

export const fieldValidationCheckboxFactory = (field: FieldCheckbox) => {
  let rules = yup.string()

  if (field.required) {
    rules = rules
      .required()
      .oneOf([field.checkedValue], 'Field must be checked')
  }

  return rules
}

export const fieldValidationDropdownListFactory = (field: FieldSelect) => {
  let rules = yup.array(yup.string()).min(1)

  if (field.maxValues) {
    rules = rules.max(field.maxValues)
  }

  return rules
}

export const fieldValidationDateFactory = (field: FieldDate) => {
  let rules = yup.string().datetime()

  if (field.required) {
    rules = rules.required()
  }

  return rules
}

export const fieldValidationFactory = (
  field: GeneralFieldType
): yup.AnySchema => {
  switch (field.type) {
    case FieldType.Text:
      return fieldValidationTextFactory(field)
    case FieldType.Number:
      return fieldValidationNumberFactory(field)
    case FieldType.Checkbox:
      return fieldValidationCheckboxFactory(field)
    case FieldType.Select:
      return fieldValidationDropdownListFactory(field)
    case FieldType.Date:
      return fieldValidationDateFactory(field)
  }
}
