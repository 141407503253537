import React from 'react'
import { cn } from '../../utils/shadcn'
import { useCondition } from '../../../hooks/use-condition'
import { useLocation } from 'react-router-dom'

type Props = {
  className?: string
  color?: string
  style?: string
  direction?: 'horizontal' | 'vertical'
  width?: number
  condition?: string
}

export const Divider: React.FunctionComponent<Props> = ({
  className = 'my-2',
  color,
  style = 'solid',
  direction = 'horizontal',
  width,
  condition,
}) => {
  const location = useLocation()
  const cid =
    (new URLSearchParams(location.search).get('cid') as string) ?? 'default'

  const isConditionValid = useCondition(condition, cid)

  if (!isConditionValid) return null

  return (
    <div className={cn(`relative`, className)}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div
          className={cn(
            'border-gray-300',
            `border-${style}`,
            direction === 'horizontal' ? 'w-full border-t' : 'h-full border-l'
          )}
          style={{
            borderColor: color,
            [direction === 'horizontal'
              ? 'borderTopWidth'
              : 'borderLeftWidth']: `${width}px`,
          }}
        />
      </div>
    </div>
  )
}
