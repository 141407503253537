/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo } from 'react'

import _ from 'lodash'

import Holidays from 'date-holidays'
import NumericFormat from 'react-number-format'
import * as SliderPrimitive from '@radix-ui/react-slider'

import dayjs from 'dayjs'
import { useFormContext } from 'react-hook-form'
import { css } from '@emotion/css'
import { Button } from '../../components/ui/button'
import { Calendar } from '../../components/ui/calendar'
import { FormControl, FormField } from '../../components/ui/form'
import { Input } from '../../components/ui/input'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../components/ui/popover'
import { CalendarIcon, Check, ChevronDown } from 'lucide-react'
import 'react-datepicker/dist/react-datepicker.css'
import { cn } from '../../utils/shadcn'
import {
  ExcludedDateType,
  FieldNumericStyle,
  FieldType,
  GeneralFieldType,
  ResolvedFieldStyleReference,
} from '../types'
import { LabelType } from '../../ui-library/types/label'
import { useEngine } from '../../contexts/engine-context'

import { useLocation } from 'react-router-dom'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { getDependencies } from '../../utilities/dependencies'
import { useCondition } from '../../../hooks/use-condition'
import { Multiselect } from './multiselect'
import { MultiRadioSelect } from './multi-radio-select'
import { isNumber } from '../../../helpers/form'

dayjs.extend(utc)
dayjs.extend(advancedFormat)

type Props = {
  field: GeneralFieldType
  title?: LabelType
  description?: LabelType
  selectField?: () => void
}

export const Field: React.FunctionComponent<Props> = ({
  field: item,
  selectField,
}) => {
  const { separators, locale, nunjucks } = useEngine()
  const location = useLocation()
  const cid =
    (new URLSearchParams(location.search).get('cid') as string) ?? 'default'
  const isConditionValid = useCondition(item.condition, cid)

  const { watch, setValue, trigger } = useFormContext()

  const { id, value: itemValue } = item

  const values = watch()

  const dataKey = `${cid}.${id}`

  useEffect(() => {
    if (!isConditionValid) {
      setValue(dataKey, item.value)
    }
  }, [isConditionValid])

  const style = (item.theme as ResolvedFieldStyleReference).style

  // Check if field is calculated
  const isCalculated = React.useMemo(
    () => typeof item.value === 'string' && item.value.includes('{{'),
    [itemValue]
  )

  const handleBlur = async (
    key: string,
    value: string | number | string[] | number[] | boolean
  ) => {
    const isValid = await trigger(key)
    if (isValid) {
      //   EngineService.saveField(key, value)
    }
  }

  // Generate a list of dependencies on which the current field depends
  const dependencies = isCalculated
    ? getDependencies(item.value as string).map((field) => values[cid]?.[field])
    : []

  // Update value on the form cotext when a depencey changes
  React.useEffect(() => {
    if (isCalculated) {
      try {
        switch (item.type) {
          case FieldType.Date:
            setValue(
              dataKey,
              dayjs(
                nunjucks.renderString(item.value as string, {
                  field: values[cid],
                })
              ).toISOString()
            )
            break
          case FieldType.Number:
            setValue(
              dataKey,
              item.round
                ? Math.round(
                    Number(
                      eval(
                        nunjucks.renderString(item.value as string, {
                          field: values[cid],
                        })
                      )
                    )
                  )
                : Number(
                    eval(
                      nunjucks.renderString(item.value as string, {
                        field: values[cid],
                      })
                    )
                  )
            )
            break
          default:
            setValue(
              dataKey,
              nunjucks.renderString(item.value as string, {
                field: values[cid],
              })
            )
        }
      } catch (error) {
        setValue(dataKey, '')
      }
    } else if ([undefined, null].includes(watch(dataKey))) {
      switch (item.type) {
        case FieldType.Date:
          setValue(dataKey, item.value)
          break
        case FieldType.Number:
          if (isNumber(item.value)) {
            setValue(
              dataKey,
              item.round ? Math.round(Number(item.value)) : Number(item.value)
            )
          }
          break
        default:
          setValue(dataKey, item.value)
      }
    }
  }, dependencies)

  const color = style.borderFocusColor.value

  const className = cn(
    css`
      background-color: ${style.backgroundColor.value};
      border-color: ${style.borderColor.value};
      color: ${style.textColor.value};
      padding-top: ${style.paddingTop}px !important;
      padding-right: ${(item.type === FieldType.Number &&
      item.currencyCode?.length
        ? 46
        : 0) + style.paddingRight}px !important;
      padding-bottom: ${style.paddingBottom}px !important;
      padding-left: ${(item.type === FieldType.Number &&
      item.currencyCode?.length
        ? 28
        : 0) + style.paddingLeft}px !important;
      &:focus {
        background-color: ${style.backgroundFocusColor.value};
        border-color: ${style.borderFocusColor.value};
        color: ${style.textFocusColor.value};
      }
    `,
    style.borderRadius,
    style.borderWidth,
    style.borderStyle,
    style.textSize,
    style.textWeight
  )

  const component = React.useMemo(() => {
    switch (item.type) {
      case FieldType.Text:
        return (
          <FormField
            name={dataKey}
            render={({ field }) => (
              <Input
                className={cn(className, 'w-72')}
                type="text"
                id={dataKey}
                readOnly={item.readOnly}
                placeholder={item.placeholder.text}
                {...field}
                onBlur={() => {
                  field.onBlur()
                  handleBlur(dataKey, field.value)
                }}
              />
            )}
          />
        )

      case FieldType.Number:
        const currencySymbol = item.currencyCode?.length
          ? (0)
              .toLocaleString(locale.replace('_', '-'), {
                style: 'currency',
                currency: item.currencyCode,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
              .replace(/\d/g, '')
              .trim()
          : undefined

        const color = style.borderFocusColor.value

        return (
          <FormField
            name={dataKey}
            render={({ field }) =>
              item.style === FieldNumericStyle.Field ? (
                <div className="relative w-72">
                  {currencySymbol && (
                    <span
                      className="absolute h-full flex items-center top-0 left-0 pl-3"
                      style={{ color }}
                    >
                      {currencySymbol}
                    </span>
                  )}
                  <NumericFormat
                    lang="en-US"
                    thousandSeparator={separators.group}
                    decimalSeparator={separators.decimal}
                    decimalScale={item.round ? 0 : 2}
                    fixedDecimalScale
                    readOnly={item.readOnly}
                    formNoValidate
                    defaultValue={field.value}
                    value={field.value}
                    onValueChange={(values) =>
                      field.onChange(
                        Number(
                          values.formattedValue
                            .replaceAll(separators.group, '')
                            .replaceAll(separators.decimal, '.')
                        )
                      )
                    }
                    onBlur={() => handleBlur(dataKey, field.value)}
                    type="text"
                    className={cn(
                      'flex h-10 w-full rounded-md border border-input bg-background pl-12 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                      className
                    )}
                  />
                </div>
              ) : (
                <div className="w-full max-w-sm mx-auto" onClick={selectField}>
                  <div className="relative pt-10 flex-1">
                    <SliderPrimitive.Root
                      defaultValue={[field.value || item.minValue || 0]}
                      min={(item.minValue as number) || 0}
                      max={(item.maxValue as number) || 100}
                      value={[field.value || item.minValue || 0]}
                      step={1}
                      disabled={item.readOnly || isCalculated}
                      className={cn(
                        'relative flex w-full touch-none select-none items-center'
                      )}
                      onValueChange={(newValue) => field.onChange(newValue[0])}
                    >
                      <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-secondary">
                        <SliderPrimitive.Range
                          className={`absolute h-full`}
                          style={{
                            backgroundColor: (
                              item.theme as ResolvedFieldStyleReference
                            ).style.borderColor.value,
                          }}
                        />
                      </SliderPrimitive.Track>
                      <SliderPrimitive.Thumb
                        className={`relative block h-5 w-5 rounded-full border-2 bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50`}
                        style={{
                          borderColor: (
                            item.theme as ResolvedFieldStyleReference
                          ).style.borderColor.value,
                        }}
                      >
                        <span
                          className={`absolute text-sm font-medium -top-8`}
                          style={{
                            color: (item.theme as ResolvedFieldStyleReference)
                              .style.textColor.value,
                          }}
                        >
                          {field.value}
                        </span>
                      </SliderPrimitive.Thumb>
                    </SliderPrimitive.Root>
                  </div>
                  <div className="w-full flex justify-between mt-4">
                    <span className="text-sm text-muted-foreground">
                      {item.minValue || 0}
                    </span>
                    <span className="text-sm text-muted-foreground">
                      {item.maxValue || 100}
                    </span>
                  </div>
                </div>
              )
            }
          />
        )

      case FieldType.Select:
        const radioClassIndicator = cn(
          css`
            background-color: ${style.backgroundColor.value};
            border-color: ${style.borderColor.value};
            color: ${style.textColor.value};

            &:has([data-state='checked']) {
              background-color: ${style.backgroundColor.value};
              border-color: ${style.borderFocusColor.value};
              color: ${style.borderColor.value};
            }
          `,
          style.borderRadius,
          style.borderWidth,
          style.borderStyle,
          style.textSize,
          style.textWeight
        )

        return (
          <FormField
            name={dataKey}
            render={({ field }) =>
              item.style === 'dropdown' ? (
                <Multiselect
                  item={item}
                  className={className}
                  currentValue={field.value}
                  dependecies={item.values.reduce(
                    (accumulator, option) => [
                      ...accumulator,
                      ...getDependencies(option.condition),
                    ],
                    []
                  )}
                  values={item.values.filter(({ condition }) =>
                    condition
                      ? nunjucks.renderString(condition, {
                          field: Object.fromEntries(
                            Object.entries(values[cid || 'default'] || {}).map(
                              ([key, value]) => [
                                key,
                                !isNaN(Number(value)) ? Number(value) : value,
                              ]
                            )
                          ),
                        }) === 'true'
                      : true
                  )}
                  context={values[cid]}
                  onChange={(value) => field.onChange(value)}
                />
              ) : (
                <MultiRadioSelect
                  item={item}
                  className={className}
                  radioClassIndicator={radioClassIndicator}
                  currentValue={field.value}
                  dependecies={item.values.reduce(
                    (accumulator, option) => [
                      ...accumulator,
                      ...getDependencies(option.condition),
                    ],
                    []
                  )}
                  values={item.values.filter(({ condition }) =>
                    condition
                      ? nunjucks.renderString(condition, {
                          field: Object.fromEntries(
                            Object.entries(values[cid || 'default'] || {}).map(
                              ([key, value]) => [
                                key,
                                !isNaN(Number(value)) ? Number(value) : value,
                              ]
                            )
                          ),
                        }) === 'true'
                      : true
                  )}
                  context={values[cid]}
                  onChange={(value) => field.onChange(value)}
                />
              )
            }
          />
        )

      case FieldType.Date:
        const holidays = new Holidays(locale.split('_')[1])
        return (
          <FormField
            name={dataKey}
            render={({ field }) => (
              <div onClick={selectField}>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant={'outline'}
                      className={cn(
                        'pl-3 text-left font-normal w-72',
                        !field.value && 'text-muted-foreground',
                        selectField && 'pointer-events-none',
                        className
                      )}
                      onBlur={() => handleBlur(dataKey, field.value)}
                    >
                      {field.value ? (
                        dayjs(field.value).format(item.format || 'DD/MM/YYYY')
                      ) : (
                        <span>{item.placeholder?.text || 'Pick a date'}</span>
                      )}
                      <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={dayjs(field.value).toDate()}
                      onSelect={(date) => {
                        field.onChange(dayjs(date).toISOString())
                        handleBlur(dataKey, field.value)
                      }}
                      disabled={(date) => {
                        const isOutOfRange =
                          (item.minDate &&
                            dayjs(date).isBefore(
                              dayjs(item.minDate).isValid()
                                ? dayjs(item.minDate).startOf('day')
                                : dayjs(
                                    nunjucks.renderString(item.minDate, {
                                      field: values[cid],
                                    })
                                  )
                            )) ||
                          (item.maxDate &&
                            dayjs(date).isAfter(
                              dayjs(item.maxDate).isValid()
                                ? dayjs(item.maxDate).endOf('day')
                                : dayjs(
                                    nunjucks.renderString(item.maxDate, {
                                      field: values[cid],
                                    })
                                  )
                            ))
                        const isExcludedDate = item.excludedDates?.includes(
                          date.getDate()
                        )
                        const isExcludedDay = item.excluded?.some((type) => {
                          switch (type) {
                            case ExcludedDateType.Holidays:
                              return holidays.isHoliday(date)
                            case ExcludedDateType.Weekends:
                              return date.getDay() === 0 || date.getDay() === 6
                            case ExcludedDateType.Today:
                              return dayjs(date).isSame(dayjs(), 'day')
                            case ExcludedDateType.Tomorrow:
                              return dayjs(date).isSame(
                                dayjs().add(1, 'day'),
                                'day'
                              )
                            case ExcludedDateType.Yesterday:
                              return dayjs(date).isSame(
                                dayjs().subtract(1, 'day'),
                                'day'
                              )
                            default:
                              return false
                          }
                        })
                        return isOutOfRange || isExcludedDate || isExcludedDay
                      }}
                    />
                  </PopoverContent>
                </Popover>
              </div>
            )}
          />
        )
    }
  }, [item, values])

  if (!item || !isConditionValid) {
    return null
  }

  return (
    <div className={cn(item.readOnly && 'pointer-events-none opacity-75')}>
      {component}
      {/* TODO: Remove on release */}
      <div className="text-xs text-muted-foreground mt-2">
        <span className="font-bold">{item.id}</span>{' '}
        {isCalculated && item.value}
      </div>
    </div>
  )
}
