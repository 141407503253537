import dayjs from 'dayjs'
import nunjucks from 'nunjucks'
import { getCurrencySymbol } from '../ui-library/utils/currency'

const env = new nunjucks.Environment()

env.addFilter('format_date', (value: string, format: string) => {
  // Implement your custom pipe logic here
  return dayjs(value).format(format || 'DD/MM/YYYY')
})

env.addFilter('fromjson', (value: string) => JSON.parse(value))

env.addFilter(
  'exists_in',
  (
    value: string | number | (string | number)[],
    lst: (string | number)[],
    method?: string
  ) => {
    if (typeof value === 'string' || typeof value === 'number') {
      return lst.includes(value)
    }

    return value[method === 'all' ? 'every' : 'some']((v) => lst.includes(v))
  }
)

env.addFilter('format_currency', (value: number) => {
  // TODO: Retrieve currency from the context
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(value)
})

env.addFilter('currency_symbol', (value: string) => {
  return getCurrencySymbol(value)
})

env.addFilter('min', (arr: number[]) => Math.min(...arr))
env.addFilter('max', (arr: number[]) => Math.max(...arr))

export default env
