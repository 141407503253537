import React, { PropsWithChildren } from 'react'

import {
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
} from '../../../components/ui/form'
import { cn } from '../../../utils/shadcn'
import { RowFieldType as Row } from '../../types/row'
import { Label } from '../label'
import { Field } from '../field'
import ErrorMessage from '../error-message'
import { useLocation } from 'react-router-dom'
import { ResolvedReference } from '../../types'

interface RowFieldProps {
  row: Row
}

export const RowField: React.FunctionComponent<
  PropsWithChildren<RowFieldProps>
> = ({ row }) => {
  const { justify, title, description } = row

  const location = useLocation()

  const cid =
    (new URLSearchParams(location.search).get('cid') as string) ?? 'default'

  const dataKey = `${cid}.${row?.field?.id}`

  return (
    <div
      className="flex flex-col justify-end"
      style={{
        justifyContent: justify || 'start',
        backgroundColor: (row.theme.backgroundColor as ResolvedReference).value,
      }}
    >
      <FormItem>
        <FormLabel>
          <Label label={title} />
        </FormLabel>
        <FormControl>{row.field && <Field field={row.field} />}</FormControl>
        <FormDescription>
          <Label label={description} />
        </FormDescription>
        {/* TODO: Add custom fomr message */}

        {row.field && <ErrorMessage name={dataKey} />}
      </FormItem>
    </div>
  )
}
