import React from 'react'

import { RowButtonsType as Row, RowContentJustify } from '../../types/row'
import { ResolvedReference } from '../../../ui-library/types'
import { cn } from '../../../utils/shadcn'
import { Button } from '../button'

type Props = {
  row: Row
}

export const RowButtons: React.FunctionComponent<Props> = ({ row }) => {
  const { justify } = row

  return (
    <div
      className="flex flex-row justify-end space-x-2 w-full overflow-hidden"
      style={{
        justifyContent: justify || 'start',
        backgroundColor: (row.theme.backgroundColor as ResolvedReference).value,
      }}
    >
      {row.button1 && (
        <div
          className={cn(
            justify === RowContentJustify.STRETCH && 'flex-1',
            'overflow-hidden'
          )}
        >
          <Button item={row.button1} />
        </div>
      )}
      {row.button2 && (
        <div
          className={cn(
            justify === RowContentJustify.STRETCH && 'flex-1',
            'overflow-hidden'
          )}
        >
          <Button item={row.button2} />
        </div>
      )}
    </div>
  )
}
