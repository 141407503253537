import { LOCAL_STORAGE_KEYS, ROUTES } from '../constants'
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react'
import { Config } from '../engine/ui-library/types/config'
import { UIService } from '../services'
import LoadingOptions from '../components/LoadingOptions'
import { EngineLayout, Mode } from '../engine/contexts/engine-context'
import { getErrorMessage } from '../helpers'
import { PortalConfig } from '../helpers/useConfig'
import { useLocation } from 'react-router-dom'
import { MESSAGE_STATUS, useMessages } from '../context/messages'
import { HttpClient } from '../clients/http-client'

interface Props extends PropsWithChildren {
  appConfig: PortalConfig
}

export const EngineConfigProvider: FunctionComponent<Props> = ({
  appConfig,
}) => {
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const [config, setConfig] = useState<Config>(undefined)
  const { addToast } = useMessages()

  useEffect(() => {
    setIsLoading(true)
    UIService.fetchUserOptions().then((config) => {
      setConfig(config)
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (
      location.pathname === ROUTES.ENGINE_DASHBOARD &&
      localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_FLAG) === 'true'
    ) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_OPTIONS)
      setIsLoading(true)
      UIService.fetchUserOptions().then((config) => {
        setConfig(config)
        setIsLoading(false)
        localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_FLAG)
      })
    }
  }, [location])

  return isLoading ? (
    <LoadingOptions loading />
  ) : (
    <EngineLayout
      labels={{
        historyNoItems: 'No items',
        historySubmissionDate: 'Submission date',
        historyLoading: 'Loading...',
      }}
      config={config}
      locale="en_GB"
      enginePath="/dashboard"
      mode={Mode.LIVE}
      actionErrorCallback={(error) => {
        const errorMessage = getErrorMessage(error)
        addToast(MESSAGE_STATUS.ERROR, errorMessage)
      }}
      httpClient={HttpClient}
    />
  )
}
