import { FunctionComponent, useEffect, useRef } from 'react'

import { CardType } from '../ui-library/types/card'
import { RowFactory } from '../row/row-factory'
import { ResolvedReference, RowId } from '../ui-library'
import { cn } from '../utils/shadcn'
import { Mode, useEngine } from '../contexts/engine-context'
import { useCondition } from '../../hooks/use-condition'
import { useLocation } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'

type Props = {
  card: CardType
}

export const CardFactory: FunctionComponent<Props> = ({ card }) => {
  const { rows, theme, saveToHistory, columnSpan = 1 } = card
  const { mode, httpClient } = useEngine()
  const { setValue } = useFormContext()
  const location = useLocation()
  const cid =
    (new URLSearchParams(location.search).get('cid') as string) ?? 'default'
  const isConditionValid = useCondition(card.condition, cid)

  const cardRef = useRef<HTMLDivElement>(null)

  // Reset fields of card if condition is not valid
  useEffect(() => {
    rows.forEach((row) => {
      if (row.layoutId === RowId.Field && !isConditionValid) {
        const dataKey = `${cid}.${row.field.id}`
        setValue(dataKey, row.field.value)
      } else if (row.layoutId === RowId.Checkbox && !isConditionValid) {
        const dataKey = `${cid}.${row.field.id}`
        setValue(
          dataKey,
          row.field.checked ? row.field.checkedValue : row.field.uncheckedValue
        )
      }
    })
  }, [isConditionValid, rows, cid, setValue])

  useEffect(() => {
    if (
      mode === Mode.LIVE &&
      saveToHistory &&
      cardRef.current &&
      isConditionValid
    ) {
      httpClient.post('/me/actions/history', {
        body: cardRef.current.outerHTML,
      })
    }
  }, [])

  if (!isConditionValid) return null

  return (
    <div
      className={cn(
        theme.borderRadius,
        theme.borderWidth,
        theme.borderStyle,
        'p-4 overflow-hidden'
      )}
      style={{
        backgroundColor: (theme.backgroundColor as ResolvedReference).value,
        paddingTop: theme.paddingTop,
        paddingRight: theme.paddingRight,
        paddingBottom: theme.paddingBottom,
        paddingLeft: theme.paddingLeft,
        gridColumn: `span ${columnSpan}`,
      }}
      ref={cardRef}
    >
      {rows.map((row, index) => (
        <RowFactory row={row} key={index} />
      ))}
    </div>
  )
}
