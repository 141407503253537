import { ButtonEditType, ButtonType } from './button'
import { CardType, CardEditType } from './card'
import { LabelEditType, LabelType } from './label'
import { ColorReference } from './theme'

export enum PageLayoutId {
  MAIN = 'main',
  MAIN_LEFTBAR = 'main-leftbar',
  MAIN_TOPBAR = 'main-topbar',
  MAIN_RIGHTBAR = 'main-rightbar',
  MAIN_BOTTOMBAR = 'main-bottombar',
}

export enum PageLayoutWidth {
  FULL = 'full',
  NARROW = 'narrow',
}

export type PageHeaderTheme = {
  backgroundColor: ColorReference
}

export type PageTheme = {
  backgroundColor: ColorReference
}

export enum PageType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  SERVICE = 'service',
}

export type PageHeaderType = {
  title?: LabelType
  button?: ButtonType
  description?: LabelType
  theme: PageHeaderTheme
}

export type PageHeaderEditType = {
  title?: LabelEditType
  button?: ButtonEditType
  description?: LabelEditType
  theme: PageHeaderTheme
}

export type MainContentType = {
  cards: CardType[]
  columns: number
}

export type SideContentType = {
  cards: CardType[]
}

export type PageContentType = {
  main: MainContentType
  side: SideContentType
}

export type PageContentEditType = {
  cards: CardEditType[]
}
export type BasePageType = {
  type: PageType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  analyticsTag: Record<string, any>
  refreshOptions?: boolean
}

export type PageMetadataType = {
  title: string
  description: string
}

export type BasePageEditType = {
  type: PageType
  id: string
  path: string
  metadata: PageMetadataType
}

export type InternalPageType = BasePageType & {
  type: PageType.INTERNAL
  layoutId: PageLayoutId
  navigationBar: boolean
  header: PageHeaderType
  content: PageContentType
  layoutWidth: PageLayoutWidth
  theme: PageTheme
}

export type InternalPageEditType = BasePageEditType & {
  type: PageType.INTERNAL
  layoutId: PageLayoutId
  navigationBar: boolean
  cards: CardEditType[]
  header: PageHeaderEditType
  layoutWidth: PageLayoutWidth
  theme: PageTheme
}

export enum ExternalPageTarget {
  _SELF = '_self',
  _BLANK = '_blank',
}

export type ExternalPageType = BasePageType & {
  type: PageType.EXTERNAL
  target: ExternalPageTarget
  url: string
}

export type ExternalPageEditType = BasePageEditType & {
  type: PageType.EXTERNAL
  target: ExternalPageTarget
  url: string
}

export type ServicePageType = BasePageType & {
  type: PageType.SERVICE
  target: ExternalPageTarget
  serviceRequest: {
    id: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any
  }
}

export type ServicePageEditType = BasePageEditType & {
  type: PageType.SERVICE
  target: ExternalPageTarget
  serviceRequest: {
    id: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any
  }
}

export type ServiceResponseDto = {
  type: 'redirect'
  url: string
}

export type GeneralPageType =
  | InternalPageType
  | ExternalPageType
  | ServicePageType

export type GeneralPageEditType =
  | InternalPageEditType
  | ExternalPageEditType
  | ServicePageEditType
