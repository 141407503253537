import { FormField, FormMessage } from '../../components/ui/form'

interface ErrorMessageProps {
  name: string
}

const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({ name }) => (
  <FormField name={name} render={() => <FormMessage />} />
)

export default ErrorMessage
