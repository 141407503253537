import dayjs from 'dayjs'
import { HttpClient } from '../contexts/engine-context'
import { GeneralPageType } from '../ui-library/types/page'

export const trackPageView = async (
  page: GeneralPageType,
  httpClient: HttpClient
) => {
  const enhancedAnalyticsTag = {
    ...page.analyticsTag,
    sequenceNumber: dayjs().unix(),
  }

  await httpClient
    .post('/analytics/page-views', {
      analyticsTag: enhancedAnalyticsTag,
    })
    .catch((error) => {
      console.error(error)
    })
}
