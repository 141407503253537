/* eslint-disable no-template-curly-in-string */
import React, { Suspense } from 'react'
import { ThemeProvider } from 'styled-components'
import Loader from './components/Loader'
import { GlobalStyle } from './components/commonStyles'
import MessagesProvider from './context/messages'
import * as themeConfig from './theme'
import { ThemeT } from './theme/types'
import useConfig from './helpers/useConfig'
import { Router } from './router'
import * as Yup from 'yup'

import './App.css'

Yup.setLocale({
  mixed: {
    required: 'This is a required field',
    notType: 'This is a required field',
  },
  string: {
    min: 'Minimum ${min} characters allowed',
    max: 'Maximum ${max} characters allowed',
  },
  number: {
    min: 'Value must be greater than or equal to ${min}',
    max: 'Value must be less than or equal to ${max}',
  },
})

const theme: ThemeT = { ...themeConfig }

const App: React.FC = () => {
  const [, fontFamily] = useConfig()

  return (
    <ThemeProvider theme={theme}>
      <MessagesProvider>
        <GlobalStyle customFont={fontFamily} />
        <Suspense fallback={<Loader />}>
          <Router />
        </Suspense>
      </MessagesProvider>
    </ThemeProvider>
  )
}

export default App
