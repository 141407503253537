import React, { FunctionComponent, PropsWithChildren } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { isCustomerLoggedIn, isLoggedIn, setToken } from '../helpers'
import { useMessages, MESSAGE_STATUS } from '../context/messages'
import { authService } from '../services'
import { LOCAL_STORAGE_KEYS, ROUTES } from '../constants'
import { TFunction } from 'i18next'

type Props = {
  t: TFunction
} & PropsWithChildren

const AuthGuard: FunctionComponent<Props> = ({ children, t }) => {
  const { addToast } = useMessages()
  const { auth } = authService
  const location = useLocation()

  if (!isCustomerLoggedIn(auth)) {
    addToast(MESSAGE_STATUS.ERROR, t('errors.notAuthenticated'))

    authService.logout()

    // TODO: Check redirection Logic

    // setToken(
    //   LOCAL_STORAGE_KEYS.REDIRECT_URL,
    //   window.location.pathname + window.location.search
    // )

    return (
      <Navigate
        to={ROUTES.LOGIN}
        state={{ from: `${location.pathname}?${location.search}` }}
      />
    )
  }

  return <>{children}</>
}

export default withTranslation()(AuthGuard)
