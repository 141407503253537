import { FunctionComponent, useEffect, useMemo } from 'react'

import { RowButtons, RowField, RowId, RowLabel, RowType } from '../ui-library'

import { Row3Boxes } from '../ui-library/components/rows/row-3-boxes'
import { Row2Boxes } from '../ui-library/components/rows/row-2-boxes'
import { RowAccordion } from '../ui-library/components/rows/row-accordion'
import { RowCheckbox } from '../ui-library/components/rows/row-checkbox'
import { RowSection } from '../ui-library/components/rows/row-section'
import { RowDivider } from '../ui-library/components/rows/row-divider'
import { RowImage } from '../ui-library/components/rows/row-image'
import { useLocation } from 'react-router-dom'
import { useCondition } from '../../hooks/use-condition'
import { cn } from '../utils/shadcn'
import { useFormContext } from 'react-hook-form'

type Props = {
  row: RowType
}

export const RowFactory: FunctionComponent<Props> = ({ row }) => {
  const { layoutId, hidden } = row
  const { setValue } = useFormContext()
  const location = useLocation()
  const cid =
    (new URLSearchParams(location.search).get('cid') as string) ?? 'default'

  const isConditionValid = useCondition(row.condition, cid)

  // Reset field value if condition is not valid
  useEffect(() => {
    if (row.layoutId === RowId.Checkbox && !isConditionValid) {
      const dataKey = `${cid}.${row.field.id}`
      setValue(
        dataKey,
        row.field.checked ? row.field.checkedValue : row.field.uncheckedValue
      )
    } else if (row.layoutId === RowId.Field && !isConditionValid) {
      const dataKey = `${cid}.${row.field.id}`
      setValue(dataKey, row.field.value)
    }
  }, [isConditionValid])

  const component = useMemo(() => {
    switch (layoutId) {
      case RowId.Label:
        return <RowLabel row={row} />
      case RowId.ThreeBoxes:
        return <Row3Boxes row={row} />
      case RowId.Buttons:
        return <RowButtons row={row} />
      case RowId.Field:
        return <RowField row={row} />
      case RowId.TwoBoxes:
        return <Row2Boxes row={row} />
      case RowId.Accordion:
        return <RowAccordion row={row} />
      case RowId.Checkbox:
        return <RowCheckbox row={row} />
      case RowId.Section:
        return <RowSection row={row} />
      case RowId.Separator:
        return <RowDivider row={row} />
      case RowId.Image:
        return <RowImage row={row} />
    }
  }, [layoutId, row])

  if (!isConditionValid) return null

  return (
    <div
      className={cn(hidden && 'hidden')}
      style={{
        paddingTop: `${row.theme?.paddingTop}px`,
        paddingRight: `${row.theme?.paddingRight}px`,
        paddingBottom: `${row.theme?.paddingBottom}px`,
        paddingLeft: `${row.theme?.paddingLeft}px`,
      }}
    >
      {component}
    </div>
  )
}
