import { ArrowLeft, House } from 'lucide-react'
import { FunctionComponent } from 'react'
import { Link, To } from 'react-router-dom'
import { useEngine } from '../contexts/engine-context'

interface Props {
  isBackHidden: boolean
}

export const NavigationBar: FunctionComponent<Props> = ({ isBackHidden }) => {
  const { enginePath: prefix } = useEngine()
  return (
    <div className="flex items-center divide-x">
      <Link className="px-2" to={`${prefix}/home`}>
        <House />
      </Link>
      {!isBackHidden && (
        <Link className="px-2" to={-1 as To}>
          <ArrowLeft />
        </Link>
      )}
    </div>
  )
}
