import { FunctionComponent, useMemo } from 'react'
import {
  InternalPageType,
  PageLayoutId,
  PageLayoutWidth,
} from '../ui-library/types/page'
import { PageLayoutMain } from './layouts/page-layout-main'
import { PageLayoutRightBar } from './layouts/page-layout-right-bar'
import { cn } from '../utils/shadcn'
import { NavigationBar } from '../components/navigation-bar'
import { Header } from '../components/header'
import { PageLayoutTopBar } from './layouts/page-layout-top-bar'
import { PageLayoutLeftBar } from './layouts/page-layout-left-bar'
import { PageLayoutBottomBar } from './layouts/page-layout-bottom-bar'

type Props = {
  page: InternalPageType
}

export const InternalPage: FunctionComponent<Props> = ({ page }) => {
  const { layoutWidth, navigationBar, header, refreshOptions } = page

  const pageLayout = useMemo(() => {
    switch (page.layoutId) {
      case PageLayoutId.MAIN:
        return <PageLayoutMain page={page} />
      case PageLayoutId.MAIN_RIGHTBAR:
        return <PageLayoutRightBar page={page} />
      case PageLayoutId.MAIN_TOPBAR:
        return <PageLayoutTopBar page={page} />
      case PageLayoutId.MAIN_LEFTBAR:
        return <PageLayoutLeftBar page={page} />
      case PageLayoutId.MAIN_BOTTOMBAR:
        return <PageLayoutBottomBar page={page} />
    }
  }, [page])

  return (
    <div
      className={cn(
        layoutWidth === PageLayoutWidth.FULL ? 'w-full' : 'container mx-auto',
        'space-y-4 px-4'
      )}
    >
      {navigationBar && (
        <div className="grow">
          <NavigationBar isBackHidden={refreshOptions} />
        </div>
      )}
      {header && <Header header={header} />}
      {pageLayout}
    </div>
  )
}
