import React, { FunctionComponent } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import {
  ChangePassword,
  ForgotPassword,
  Login,
  NewPassword,
  NotFound,
  Register,
  VerifyCode,
  LinkPage,
  Otp,
  OtpChannel,
  OtpLogin,
} from '../pages'
import useConfig from '../helpers/useConfig'
import { ROUTES } from '../constants'
import MfaGuard from '../guards/mfa-guard'
import { GuestGuard } from '../guards/guest-guard'
import AuthGuard from '../guards/auth-guard'
import Header from '../components/Header'
import { MainContainer } from '../components/commonStyles'
import Footer from '../components/Footer'
import ScrollButton from '../components/ScrollButton'
import { PageFactory } from '../engine/page/page-factory'
import { EngineConfigProvider } from '../providers/engine-config-provider'

export const Router: FunctionComponent = () => {
  const [appConfig] = useConfig()

  return (
    appConfig && (
      <BrowserRouter>
        <Header config={appConfig} />
        {/* <ErrorBoundary> */}
        <MainContainer>
          <Routes>
            <Route
              path={ROUTES.HOME}
              element={<Navigate to={ROUTES.LOGIN} />}
            />

            {appConfig?.authMode === 'otp' ? (
              <>
                <Route
                  path={ROUTES.LOGIN}
                  element={
                    <GuestGuard>
                      <OtpLogin config={appConfig} />
                    </GuestGuard>
                  }
                />
                <Route
                  path={ROUTES.OTP}
                  element={
                    <MfaGuard>
                      <OtpChannel />
                    </MfaGuard>
                  }
                />
                <Route
                  path={ROUTES.OTP_CODE}
                  element={
                    <MfaGuard>
                      <Otp />
                    </MfaGuard>
                  }
                />
              </>
            ) : (
              <>
                <Route path={ROUTES.LOGIN} element={<Login />} />

                <Route
                  path={ROUTES.REGISTER_CODE}
                  element={
                    <GuestGuard>
                      <VerifyCode />
                    </GuestGuard>
                  }
                />
                <Route
                  path={ROUTES.REGISTER_NEW_PSSW}
                  element={
                    <GuestGuard>
                      <NewPassword />
                    </GuestGuard>
                  }
                />
                <Route
                  path={ROUTES.RESET_PSSW}
                  element={
                    <GuestGuard>
                      <NewPassword />
                    </GuestGuard>
                  }
                />
                <Route
                  path={ROUTES.REGISTER}
                  element={
                    <GuestGuard>
                      <Register />
                    </GuestGuard>
                  }
                />
                <Route
                  path={ROUTES.CHANGE_PSSW}
                  element={
                    <AuthGuard>
                      <ChangePassword />
                    </AuthGuard>
                  }
                />

                <Route path={ROUTES.FORGOT_PSSW} element={<ForgotPassword />} />
              </>
            )}

            <Route path={ROUTES.LINK_PAGE} element={<LinkPage />} />

            <Route
              element={
                <AuthGuard>
                  <EngineConfigProvider appConfig={appConfig} />
                </AuthGuard>
              }
            >
              <Route
                path="/dashboard"
                element={<Navigate to="/dashboard/home" />}
              />
              <Route path="/dashboard/*" element={<PageFactory />} />
            </Route>
          </Routes>
          <ScrollButton />
        </MainContainer>
        <Footer />
      </BrowserRouter>
    )
  )
}
