import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from '../theme/icons/close.svg'
import { ReactComponent as SuccessIcon } from '../theme/icons/checkmark.svg'
import { ReactComponent as ErrorIcon } from '../theme/icons/exclamation_mark.svg'
import { StyledFlexRow } from '../components/commonStyles'

const MessagesContext = React.createContext(null)
MessagesContext.displayName = 'Messages'
export const useMessages = () => React.useContext(MessagesContext)

let count = 0
const getUniqueId = () => `msg-id-${count++}`

const ToastsContainer = styled.div`
  position: fixed;
  top: 60px;
  right: 0;
  width: 100%;
  max-width: 400px;
  padding: 16px;
`

const autoDismissAfterMs = 8000
const defAutoDismiss = true

const Container = styled.div<{ status: string }>`
  padding: 12px;
  //margin-top: 16px;
  background-color: ${(props) =>
    props.status === 'error'
      ? 'var(--elements-informationPanels-errorBackgroundColor)'
      : 'var(--elements-informationPanels-successBackgroundColor)'};
  color: ${(props) =>
    props.status === 'error'
      ? 'var(--elements-informationPanels-errorTextColor)'
      : 'var(--elements-informationPanels-successTextColor)'};
  border-radius: 4px;
  font-size: 16px;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:first-of-type) {
    margin-top: 10px;
  }

  > div {
    display: flex;
    align-items: center;
  }

  span {
    padding-top: 2px;
    font-size: 14px;
    margin-left: 10px;
  }
`

const Toast = ({
  status,
  content,
  autoDismiss,
  remove,
}: {
  status: string
  content: any
  autoDismiss: boolean
  remove: any
}) => {
  React.useEffect(() => {
    if (autoDismiss) {
      const timeoutHandle = setTimeout(remove, autoDismissAfterMs)

      return () => clearTimeout(timeoutHandle)
    }
  }, [autoDismiss, remove])

  const icons = {
    success: (
      <SuccessIcon
        fill={getComputedStyle(document.documentElement).getPropertyValue(
          '--elements-informationPanels-successIconColor'
        )}
      />
    ),
    error: (
      <ErrorIcon
        fill={getComputedStyle(document.documentElement).getPropertyValue(
          '--elements-informationPanels-errorIconColor'
        )}
      />
    ),
  }

  return (
    <Container status={status}>
      <StyledFlexRow>
        <span>{icons[status]}</span>
        <span dangerouslySetInnerHTML={{ __html: content }} />
      </StyledFlexRow>
      <span>
        <CloseIcon style={{ cursor: 'pointer' }} onClick={remove} />
      </span>
    </Container>
  )
}

const MessagesProvider = ({ children }) => {
  const [toasts, setToasts] = React.useState([])

  const addToast = React.useCallback(
    (status, content, options: { autoDismiss: boolean }) => {
      const { autoDismiss = defAutoDismiss } = options || {}
      const toastId = getUniqueId()

      const toast = {
        id: toastId,
        status,
        content,
        autoDismiss,
        remove: () => {
          setToasts((latestToasts) =>
            latestToasts.filter(({ id }) => id !== toastId)
          )
        },
      }

      setToasts((latestToasts) => [...latestToasts, toast])
    },
    []
  )

  const contextValue = React.useMemo(
    () => ({
      addToast,
    }),
    [addToast]
  )

  return (
    <MessagesContext.Provider value={contextValue}>
      {ReactDOM.createPortal(
        <ToastsContainer>
          {toasts.map((toast) => (
            <Toast key={toast.id} {...toast} />
          ))}
        </ToastsContainer>,
        document.body
      )}
      {children}
    </MessagesContext.Provider>
  )
}

export default MessagesProvider

export const MESSAGE_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
}
