import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { TFunction, withTranslation } from 'react-i18next'

import { UIService } from '../services'
import { ROUTES } from '../constants'
import LoadingOptions from '../components/LoadingOptions'
import {
  StyledColumn,
  StyledGrid,
  StyledWrapper,
  StyledLink,
} from '../components/commonStyles'
import { getErrorMessage } from '../helpers'

interface LinkPageProps {
  t: TFunction
}

type Params = {
  id: string
}

const LinkPage: React.FC<LinkPageProps> = (props) => {
  const { id } = useParams<Params>()
  const { t } = props
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState('')

  React.useEffect(() => {
    UIService.getRedirectLink(id)
      .then((re) => {
        setLoading(false)
        if (re.link) {
          window.location.href = re.link
        } else {
          navigate(ROUTES.HOME)
        }
      })
      .catch((e) => {
        setLoading(false)
        const errorMessage = getErrorMessage(e)
        setError(errorMessage)
      })
  }, [id, navigate, t])

  if (error) {
    return (
      <StyledWrapper align="center" margin="195px auto">
        <StyledGrid halign="center">
          <StyledColumn
            size={{ md: 4 / 8, lg: 5 / 12 }}
            halign="center"
            direction="column"
          >
            <div id="magin-link-not-found">
              <h1
                dangerouslySetInnerHTML={{
                  __html: t('magicLinks.heading', ''),
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: error,
                }}
              />
              <StyledLink to={ROUTES.HOME}>
                {props.t('magicLinks.linkToHome', '')}
              </StyledLink>
            </div>
          </StyledColumn>
        </StyledGrid>
      </StyledWrapper>
    )
  }

  return (
    <LoadingOptions
      loading={loading}
      text={t('magicLinks.loading', '')}
      loadingText={t(['magicLinks.loading', ''])}
    />
  )
}

export default withTranslation()(LinkPage)
