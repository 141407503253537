import { useFormContext } from 'react-hook-form'
import { useEngine } from '../engine/contexts/engine-context'
import { useCallback, useEffect, useState } from 'react'

export const useCondition = (condition?: string, cid?: string) => {
  const { watch } = useFormContext()
  const { nunjucks } = useEngine()
  const values = watch()

  const [isValid, setIsValid] = useState(!condition)

  const checkCondition = useCallback(() => {
    if (condition) {
      setIsValid(
        nunjucks.renderString(condition, {
          field: Object.fromEntries(
            Object.entries(values[cid || 'default'] || {}).map(
              ([key, value]) => [
                key,
                !isNaN(Number(value)) ? Number(value) : value,
              ]
            )
          ),
        }) === 'true'
      )
    } else {
      setIsValid(true)
    }
  }, [condition, values, cid, nunjucks])

  useEffect(() => {
    window.addEventListener('focusout', checkCondition)
    return () => window.removeEventListener('focusout', checkCondition)
  }, [checkCondition])

  return isValid
}
