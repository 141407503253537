import React from 'react'

import { RowLabelType as Row } from '../../types/row'
import { Label } from '../label'
import { ResolvedReference } from '../../types'

type Props = {
  row: Row
  selectLabel?: () => void
}

export const RowLabel: React.FunctionComponent<Props> = ({
  row,
  selectLabel,
}) => {
  const { justify, label } = row

  return (
    <div
      className="flex-1 flex flex-col"
      style={{
        justifyContent: justify || 'start',
        backgroundColor: (row.theme.backgroundColor as ResolvedReference).value,
      }}
    >
      <div onClick={selectLabel}>
        <Label label={label} />
      </div>
    </div>
  )
}
