import { FunctionComponent, useEffect } from 'react'
import { InternalPageType } from '../ui-library/types/page'

import { InternalPage } from './internal-page'

import { Mode, useEngine } from '../contexts/engine-context'
import { useLocation } from 'react-router-dom'
import { trackPageView } from '../utilities/analytics'
import { NotFound } from '../../pages'
import { setRefreshOptionsFlag } from '../utilities/page'

export const PageFactory: FunctionComponent = () => {
  const location = useLocation()
  const { mode, enginePath, config, httpClient } = useEngine()

  const path = `${location.pathname.replace(enginePath, '')}${location.search}`

  const page = config[path]

  // TODO: Redirect if required fields arent completed

  useEffect(() => {
    if (page) {
      if (mode === Mode.LIVE) {
        trackPageView(page, httpClient)
      }

      if (page.refreshOptions) {
        setRefreshOptionsFlag()
      }
    }
  }, [page])

  if (!page) {
    return <NotFound />
  }

  return (
    <div className="flex-1 flex flex-col py-8">
      <InternalPage page={page as InternalPageType} />
    </div>
  )
}
