import { ROUTES } from '../../constants'

import { LOCAL_STORAGE_KEYS } from '../../constants'

export const setRefreshOptionsFlag = () => {
  // Override the browser's default back button behavior
  window.history.pushState(null, '', window.location.href)
  window.onpopstate = function (event) {
    // Prevent the default back action
    event.preventDefault()

    // Redirect to the dashboard instead
    window.location.href = ROUTES.ENGINE_DASHBOARD
  }
  localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_FLAG, 'true')
}
